import { useRouter } from 'next/router'
import { signIn, useSession } from 'next-auth/react'
import { useContext, useState, useEffect } from 'react'
import { UserContext } from '../../context/UserContext'
import client from '../../../client'
import Spinner from 'components/Spinner/Spinner'
import Loader2 from 'components/Loader/Loader2'

export const Login = () => {
  const [error, setError] = useState('')
  const { status } = useSession()
  const router = useRouter()
  const { setUser } = useContext(UserContext)
  const [showNumber, setShowNumber] = useState(false)
  const [phone, setPhone] = useState('')
  const [userMail, setUserMail] = useState('')
  const [loading, setLoading] = useState(false)

  if (status === 'authenticated') {
    router.push('/')
  }

  const handleSubmit = async (e) => {
    setLoading(true)
    e.preventDefault()

    const email = e.target.email.value.toLowerCase()
    const password = e.target.password.value
    setUserMail(email)

    try {
      const login = await signIn('sanity-login', {
        redirect: false,
        email,
        password,
      })

      console.log('login', login, email, password)

      if (login.ok) {
        console.log('login ok')
        const query = `*[_type == "user" && email == "${email}"]`
        const userData = await client.fetch(query)
        console.log('login userData', userData)
        if (userData) {
          localStorage.setItem('uid', userData[0]._id)
          setUser(userData[0])
          setLoading(false)
        }
      }

      if (login.error === 'pchstr must be a non-empty string') {
        setLoading(false)
        setError('You need to reset your password')
      }

      if (
        login.error !== null &&
        login.error.includes("Cannot destructure property '_id'")
      ) {
        setLoading(false)
        setError('User Not Found')
      }

      if (login.error === 'Password Invalid') {
        setLoading(false)
        setError('You Have Entered Invalid Password')
      }

      if (login.error === 'Email does not exist') {
        setLoading(false)
        setError('This Email Does Not Exist')
      }
    } catch (err) {
      console.log(err)
    }
  }

  // useEffect(() => {
  //   if (status === 'authenticated') {
  //     router.push('/');
  //   }
  // }, [status, router]);

  return (
    <>
      {/* <!-- BEGIN LOGIN --> */}
      <div className='login'>
        <div className='wrapper'>
          <div
            className='login-form js-img'
            style={{ backgroundImage: `url('/assets/img/login-form__bg.png')` }}
          >
            <form onSubmit={handleSubmit}>
              <h3>log in</h3>
              {error && (
                <div className='box-field'>
                  <button
                    className='btn'
                    style={{ backgroundColor: '#D7444C' }}
                  >
                    {error}
                  </button>
                  {error === 'You need to reset your password' && (
                    <div
                      className='login-form__bottom'
                      style={{ justifyContent: 'center' }}
                    >
                      <a
                        onClick={() =>
                          router.push({
                            pathname: '/changepw',
                          })
                        }
                      >
                        Click here to Change Your Password
                      </a>
                    </div>
                  )}
                </div>
              )}
              <div className='box-field'>
                <input
                  type='email'
                  className='form-control'
                  placeholder='Enter your email'
                  name='email'
                  autoComplete='off'
                />
              </div>
              {showNumber && (
                <div className='box-field'>
                  <input
                    type='phone'
                    className='form-control'
                    placeholder='Please enter phone number'
                    name='phone'
                    min={10}
                    max={10}
                    onChange={(e) => setPhone(e.target.value)}
                    autoComplete='off'
                  />
                </div>
              )}
              <div className='box-field'>
                <input
                  type='password'
                  className='form-control'
                  placeholder='Enter your password'
                  name='password'
                  autoComplete='off'
                />
              </div>
              {!loading ? (
                <button className='btn' type='submit'>
                  log in
                </button>
              ) : (
                <>
                  {/* <Spinner height={80} /> */}
                  <div style={{ textAlign: 'center', margin: '20px 0' }}>
                    <Loader2 />
                  </div>
                </>
              )}
              <div className='login-form__bottom'>
                <span>
                  No account?{' '}
                  <a onClick={() => router.push('/registration')}>
                    Register now
                  </a>
                </span>
                <a
                  onClick={() =>
                    router.push({
                      pathname: '/changepw',
                    })
                  }
                >
                  Forget Password?
                </a>
              </div>
            </form>
          </div>
        </div>
      </div>
      {/* <!-- LOGIN EOF   --> */}
    </>
  )
}
