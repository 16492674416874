import { Login } from 'components/Login/Login'
import { PublicLayout } from 'layout/PublicLayout'
import Head from 'next/head'

const breadcrumbsData = [
  {
    label: 'Home',
    path: '/',
  },
  {
    label: 'Log In',
    path: '/login',
  },
]
const LoginPage = () => {
  return (
    <>
      <Head>
        {/* Gtag */}

        <script
          async
          src='https://www.googletagmanager.com/gtag/js?id=G-5LS3FRLR'
        ></script>
        <script
          dangerouslySetInnerHTML={{
            __html: `
              window.dataLayer = window.dataLayer || [];
              function gtag(){dataLayer.push(arguments);}
              gtag('js', new Date());
              gtag('config', 'G-5LS3FRLR');
            `,
          }}
        />
        <title>JusplayToys - Login</title>
      </Head>

      <PublicLayout breadcrumb={breadcrumbsData} breadcrumbTitle='Log In'>
        <Login />
      </PublicLayout>
    </>
  )
}

export default LoginPage
